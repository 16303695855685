.itemDetails {
  display: inline-block;
  width: 39%;
  margin-left: 50px;
  margin-bottom: 0px;
  top: 0;
  vertical-align: top;
  background-color: #f9f9fc;
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 10px;
  border: 1px solid #ededee;
}
.itemDetails span {
  display: block;
}/*# sourceMappingURL=ShowItem.css.map */