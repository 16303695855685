// .itemDetails {
//     position: absolute;
//     z-index: 1;
//     left: 50%;
//     top: 50%;
//     width: 500px;
//     height: 100px;
//     background: white;
//     border:2px solid red;
// }

.itemDetails {
  display: inline-block;
  width: 39%;
  margin-left: 50px;
  margin-bottom: 0px;
  top: 0;
  vertical-align: top;
  background-color: #f9f9fc;
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 10px;
  border: 1px solid #ededee;
  span {
    display: block;
  }
}
