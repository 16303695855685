.adminWrapper {
  width: 100%;
}

.ToogleElement {
  margin-left: 5px;
  border: 1px solid white;
  background: #f9f9fc;
  padding: 5px;
  margin-top: 0px;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ToogleElement:hover {
  background-color: #ebebebaf;
}
.ToogleElement p {
  font-weight: 600;
  cursor: pointer;
}

.Toogle {
  min-width: 200px;
  display: inline-block;
}

body {
  background-color: #fdfdfd;
}
