.navigationButton {
  padding: 8px;
  background: white;
  text-decoration: none;
  font-size: 1.3em;
  color: #114828;
  &:hover {
    padding-bottom: -2px;
    border-bottom: 2px solid #114828;
  }
}
