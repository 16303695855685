/* Add a black background color to the top navigation */
.headerWrapper {
  background-color: #fff;
  overflow: hidden;
}

.containerFluid {
  margin-left: 30px;
  padding-left: 5px;
  padding-right: 5px;
  width: 100vw;
}

.homePageLayout {
  // background: #fafafa;
  overflow-x: hidden;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  margin: 0 auto;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

.headerWrapper {
  overflow: auto;
  color: #000;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  -webkit-box-sizing: border-box;
  .headerBlock {
    height: 20px;
    width: 2%;
    float: left;
  }
  .logoBlock {
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 8rem;
    margin-right: 50px;
    .logoImage {
      height: 90px;
    }
  }
  .headerMidBlock {
    height: 20px;
    width: 35%;
    float: left;
    position: relative;
    vertical-align: middle;

    .homePageHeadline {
      font: normal 20px/1em "montserratregular", sans-serif;
      text-transform: uppercase;
      display: block;
      width: 50%;
      p {
        margin-block-start: 2.4rem;
        margin-block-end: 5px;
      }
    }
    .homePageSlogan {
      color: #4a5157;
      display: block;
      font: normal 17px/1em "montserratregular", sans-serif;
    }
  }

  .headerLoginStatus {
    display: inline-block;
    right: 50px;
    top: 5px;
    float: right;
    font-size: 1rem;
    font-weight: 600;
    margin-right: 5vw;
    margin-top: 10px;
    a {
      text-decoration: none;
      color: #283747;
    }

    .UserOptions {
      display: block;
      position: absolute;
      right: 0px;
      top: 0px;
    }
  }

  .loginArea {
    position: absolute;
    vertical-align: middle;
    top: 0;
    right: 0;
    width: 400px;
    height: 2rem;
    background-color: #fdfefe;
    .userName {
      height: 100%;
      position: relative;
    }
    #userName {
      margin-left: 40px;
      color: #004d60;
      display: flex;
      align-items: center;
    }
  }
  .navigationBlock {
    width: 300px;
    margin-top: 4rem;
    float: right;
    a {
      font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
      text-decoration: none;
      line-height: 1.42857;
      color: #4a5157;
      display: inline;
      margin: 0 10px 0 10px;
    }
    a:hover {
      padding-bottom: 0.5vh;
      border-bottom: 0.4vh solid #273746;
    }
  }
}

/* Style the links inside the navigation bar */

.topnav a {
  float: left;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

.NavAuthorization {
  float: right;
}

// @viewport {
//   max-zoom: 200%;
// }

.hidden {
  display: none;
}

#logOut {
  cursor: pointer;
  padding: 5px;
  background-color: #f9f9fc;
  text-align: center;
}
