.headerWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.linkWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 10vw;
  margin-right: 10vw;
  width: 100%;
}

.landing {
  position: relative;
  width: 100%;
  display: inline;
  height: 10vh;
}

.logoBlockLanding {
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 8rem;
  margin-right: 50px;
}
.logoBlockLanding .logoImageLanding {
  height: 90px;
  margin-left: 30px;
}/*# sourceMappingURL=header.css.map */