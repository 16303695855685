.usersTable {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-collapse: collapse;
}
.usersTable tr:nth-child(1) {
  border-bottom: 1px solid grey;
}
.usersTable td {
  padding: 5px;
}

.addNewButton {
  font-weight: 400;
  color: white;
  border-radius: 2px;
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
  font-size: 0.9em;
  background-color: rgb(33, 139, 33);
  display: inline-block;
  cursor: pointer;
  align-items: center;
}

.userPanelWrapper {
  margin-left: 20px;
  padding-bottom: 20px;
}

.NewUserForm {
  margin-left: 20px;
}
.NewUserForm input[type=text] {
  width: 200px;
}
.NewUserForm input[type=e-mail] {
  width: 200px;
}
.NewUserForm input[type=password] {
  width: 200px;
}
.NewUserForm .FormRow {
  display: block;
}
.NewUserForm .saveLink {
  background-color: red;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px;
  color: white;
  border: 1px solid red;
  border-radius: 2px;
  cursor: pointer;
  font-size: 0.9em;
  margin-top: 20px;
  display: inline-block;
}
.NewUserForm .removeLink {
  background-color: black;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px;
  color: white;
  border: 1px solid black;
  border-radius: 2px;
  cursor: pointer;
  font-size: 0.9em;
  margin-top: 20px;
  margin-left: 10px;
  display: inline-block;
}

#edit {
  text-decoration: underline;
  cursor: pointer;
}

input[type=checkbox].toogle:before {
  height: 26px;
  margin-left: 0px;
}

.toggle {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 62px;
  height: 26px;
  display: inline-block;
  position: relative;
  margin-left: 0px;
  border-radius: 2px;
  overflow: hidden;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: grey;
  transition: background-color ease 0.3s;
}

.toggle:before {
  content: "YES  NO";
  display: block;
  position: absolute;
  z-index: 2;
  width: 28px;
  height: 22px;
  border-radius: 2px;
  background: #fff;
  left: 2px;
  top: 2px;
  font: 10px/22px Helvetica;
  text-transform: uppercase;
  font-weight: bold;
  text-indent: -26px;
  word-spacing: 40px;
  color: #fff;
  text-shadow: -1px -1px rgba(0, 0, 0, 0.15);
  white-space: nowrap;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  transition: all cubic-bezier(0.3, 1.5, 0.7, 1) 0.3s;
}

.toggle:checked {
  background-color: #4cd964;
}

.toggle:checked:before {
  left: 32px;
}

.editUserLink {
  cursor: pointer;
  color: #0077ff;
}/*# sourceMappingURL=Users.css.map */