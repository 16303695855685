h4 {
  margin-top: 0px;
}

.episodeBlock {
  padding-bottom: 5px;
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 5px;
  border-radius: 10px;
  background-color: rgba(245, 245, 245, 0.2039215686);
}

.dataButton {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 7px;
  border: 1px solid rgba(237, 91, 72, 0.3960784314);
  font-weight: 600;
  font-size: 0.75em;
  color: #ed5a48;
  margin-top: 10px;
}

.dataButton:hover {
  border: 1px solid #b44537;
  cursor: pointer;
  color: #b44537;
}

.ResultsHeader {
  display: flex;
  flex-direction: row;
}

.ResultsHeader div {
  margin-bottom: 0px;
}

.ResultsHeaderElement1 {
  flex-grow: 8;
}

.ResultsHeaderElement2 {
  flex-grow: 1;
  cursor: pointer;
}

.episodeParent {
  display: table;
}

.episodeStats span {
  padding-right: 30px;
}

/*# sourceMappingURL=QueryResults.css.map */
.ResultsWrapper {
  background-color: #fff;
  overflow: hidden;
  width: 72vw;
  margin-left: 1vw;
  display: inline-block;
  float: left;
  min-height: 70vh;
}
.ResultsWrapper .ResultsHeader {
  color: #283747;
  font-size: 1.07rem;
  font-weight: 600;
  background-color: #f9f9fc;
  border: 0px solid #f5f5f5;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 1rem 1.33rem;
  border-bottom: 2px solid #ccc;
}/*# sourceMappingURL=QueryResults.css.map */