.UserPanel {
  margin-left: 40px;
  .DataDashboard {
    background-color: #f0f0f0d3;
  }
}

.DataDashboard {
  margin-right: 40px;
  padding: 10px;
  p {
    margin-top: 5px;
  }
  span {
    font-weight: 700;
    margin-bottom: 10px;
  }
  div {
    margin-bottom: 0px;
  }

  table {
    margin-top: 20px;
  }

  .DataSetTable {
    width: 97%;
    margin-left: 10px;
    border-collapse: collapse;
    text-align: left;
    .DataSetHeader {
      th {
        border-bottom: 2px solid grey;
        padding: 0px;
        text-align: left;
        padding-bottom: 5px;
      }
    }

    .DataSetRow {
      font-size: 1em;
      font-weight: 400;
    }
    .Checkbox {
      width: 5%;
    }
    .Column1 {
      width: 35%;
      margin-left: 5%;
      span {
        cursor: pointer;
        font-weight: 400;
      }
    }
    .Column2 {
      width: 40%;
    }
    .Column3 {
      width: 20%;
      .ActionLinks {
        font-weight: 400;
        display: inline-block;
        padding: 2px;
      }
      .ActionLinks:hover {
        cursor: pointer;
        border-bottom: 0.5px solid black;
      }
    }
  }
}

.ToogleWrapper {
  display: inline-block;
  margin-bottom: 0px;
  .Toogle {
    cursor: pointer;
    margin-bottom: 0px;
    padding-bottom: 10px;
    padding-top: 10px;
    text-align: center;
    margin-bottom: 1px solid grey;
    span {
      padding-bottom: 5px;
    }
  }
  a {
    text-decoration: none;
    color: red;
    font-weight: 600;
  }
  .Active {
    background-color: #f0f0f0d3;
  }
  .Toogle:hover {
    span {
      font-weight: 600;
    }
  }
}

.userDataButton { 
  display: block;
  margin-bottom: 10px;
}
