.projectViewDiv {
  cursor: pointer;
  width: 100%;
  min-height: 30px;
  position: relative;
  padding-bottom: 20px;
  padding-left: 30px;
  background-color: #f9f9fc;
  border: 1px solid #ededee;
}
.projectViewDiv:hover {
  background-color: rgba(235, 235, 235, 0.6862745098);
}

.projectViewElements {
  width: 50%;
  max-width: 50%;
  display: inline-block;
  top: 0;
}

.fontAwesomeIcon {
  font-size: 25px;
  color: grey;
}

.projectViewDivInner {
  width: 91%;
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.projectViewDivInner span {
  margin-left: 10px;
}
.projectViewDivInner .addIcon {
  position: relative;
  float: right;
  padding-top: 5px;
  height: 1.1em;
  margin-left: 10px;
}
.projectViewDivInner .addIconDownload {
  position: relative;
  float: right;
  padding-top: 5px;
  height: 1.1em;
}/*# sourceMappingURL=ProjectViewElement.css.map */