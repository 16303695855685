/* Add a black background color to the top navigation */
.headerWrapper {
  background-color: #fff;
  overflow: hidden;
}

.containerFluid {
  margin-left: 30px;
  padding-left: 5px;
  padding-right: 5px;
  width: 100vw;
}

.homePageLayout {
  overflow-x: hidden;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.headerWrapper {
  overflow: auto;
  color: #000;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  -webkit-box-sizing: border-box;
}
.headerWrapper .headerBlock {
  height: 20px;
  width: 2%;
  float: left;
}
.headerWrapper .logoBlock {
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 8rem;
  margin-right: 50px;
}
.headerWrapper .logoBlock .logoImage {
  height: 90px;
}
.headerWrapper .headerMidBlock {
  height: 20px;
  width: 35%;
  float: left;
  position: relative;
  vertical-align: middle;
}
.headerWrapper .headerMidBlock .homePageHeadline {
  font: normal 20px/1em "montserratregular", sans-serif;
  text-transform: uppercase;
  display: block;
  width: 50%;
}
.headerWrapper .headerMidBlock .homePageHeadline p {
  -webkit-margin-before: 2.4rem;
          margin-block-start: 2.4rem;
  -webkit-margin-after: 5px;
          margin-block-end: 5px;
}
.headerWrapper .headerMidBlock .homePageSlogan {
  color: #4a5157;
  display: block;
  font: normal 17px/1em "montserratregular", sans-serif;
}
.headerWrapper .headerLoginStatus {
  display: inline-block;
  right: 50px;
  top: 5px;
  float: right;
  font-size: 1rem;
  font-weight: 600;
  margin-right: 5vw;
  margin-top: 10px;
}
.headerWrapper .headerLoginStatus a {
  text-decoration: none;
  color: #283747;
}
.headerWrapper .headerLoginStatus .UserOptions {
  display: block;
  position: absolute;
  right: 0px;
  top: 0px;
}
.headerWrapper .loginArea {
  position: absolute;
  vertical-align: middle;
  top: 0;
  right: 0;
  width: 400px;
  height: 2rem;
  background-color: #fdfefe;
}
.headerWrapper .loginArea .userName {
  height: 100%;
  position: relative;
}
.headerWrapper .loginArea #userName {
  margin-left: 40px;
  color: #004d60;
  display: flex;
  align-items: center;
}
.headerWrapper .navigationBlock {
  width: 300px;
  margin-top: 4rem;
  float: right;
}
.headerWrapper .navigationBlock a {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-decoration: none;
  line-height: 1.42857;
  color: #4a5157;
  display: inline;
  margin: 0 10px 0 10px;
}
.headerWrapper .navigationBlock a:hover {
  padding-bottom: 0.5vh;
  border-bottom: 0.4vh solid #273746;
}

/* Style the links inside the navigation bar */
.topnav a {
  float: left;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

.NavAuthorization {
  float: right;
}

.hidden {
  display: none;
}

#logOut {
  cursor: pointer;
  padding: 5px;
  background-color: #f9f9fc;
  text-align: center;
}/*# sourceMappingURL=Header.css.map */