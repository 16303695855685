.itemDetails {
  display: inline-block;
  width: 39%;
  margin-left: 50px;
  margin-bottom: 0px;
  top: 0;
  vertical-align: top;
  background-color: #f9f9fc;
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 10px;
  border: 1px solid #ededee;
  span {
    display: block;
  }
}

.BackButton {
  border: 0px;
  font-size: 0.9em;
  padding: 10px;
  margin-bottom: 10px;
  display: block;
  width: fit-content;
  background: #f3f3f3;
  cursor: pointer;
}

.ResultsBlock {
  h4 {
    display: inline;
  }
  h3 {
    margin-left: 1vw;
  }
}

.ViewAllFilesButton {
  padding: 5px;
  padding-left: 0px;
  cursor: pointer;
  color: rgb(0, 47, 255);
  width: fit-content;
  margin-top: 1em;
  margin-bottom: 1em;
}

.ProjectTableViewWrapper {
  margin-left: 1vw;
  table,
  tr {
    border-collapse: collapse;
    padding: 3px;
    padding-top: 10px;
    font-size: 0.9em;
  }
  tr:nth-child(1) {
    background-color: #00abaf;
    font-weight: 600;
    color: white;
  }
  table {
    width: 100%;
    border: 1px solid white;
    border-radius: 5px;
  }
  td {
    padding-top: 5px;
    padding-bottom: 5px;
    max-width: 20%;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.addDataButton {
  cursor: pointer;
}

.addIcon {
  height: 1.1em;
  margin-left: 5px;
}

.TableWithFiles {
  cursor: pointer;
  tr:nth-child(n + 2):hover {
    background-color: #f9f9fc;
  }
}
