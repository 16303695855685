.FilterWrapper {
  background-color: #fff;
  overflow: hidden;
  width: 20vw;
  height: 100%;
  display: block;
  float: left;
  margin-left: 30px;
  .FilterHeader {
    color: #283747;
    background-color: #f9f9fc;
    border: 0px solid #f5f5f5;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-size: 1.07rem;
    font-weight: 600;
    padding: 1rem 1.33rem;
    border-bottom: 2px solid #ccc;
  }
  .FilterParamsWrapper {
    margin: 0;
    // padding: 1rem 1.5rem;
    font-size: 1rem;
    color: #004d60;
    background-color: #f5f5f534;
    border-left: 2px solid #c0c0c028;
    border-bottom: 2px solid #c0c0c028;
    border-right: 2px solid #c0c0c028;

    .Chevron {
      display: inline;
      float: right;
      /* padding-right: 10px; */
      /* padding-left: 10px; */
      position: relative;
      right: 10px;
      top: 3px;
      div {
        margin-bottom: 0px;
      }
    }
    .FilterParamsHeader {
      height: 2.5rem;
      background-color: #c0c0c028;
      text-align: center;
      margin-bottom: 0px;
      div {
        margin-bottom: 0px;
      }
    }
    label {
      user-select: none;
    }
    input[type="checkbox"] {
      cursor: pointer;
    }
    .FilterCategory {
      font-size: 1.1rem;
      font-weight: 600;
      position: relative;
      top: 50%;
      -ms-transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      margin-top: 10px;
      p {
        margin-top: 0px;
      }
    }
  }
}

.react-date-picker {
  width: 100%;
  margin-bottom: 0px;
  div {
    margin-bottom: 0px;
  }
}

.react-calendar {
  width: inherit;
}

.FilterChoices {
  div {
    margin-bottom: 10px;
  }

  .react-date-picker__calendar {
    width: 19.8vw;
    bottom: 180% !important;
    left: -0.1vw !important;
  }
  div.react-date-picker__wrapper {
    margin-bottom: 0px;
  }
  div.react-date-picker__inputGroup {
    margin-bottom: 0px;
  }
  margin-bottom: 0px;
}

.FilterChoices {
  display: none;
}
.FilterChoices.active {
  display: block;
}
