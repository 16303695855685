.filtersTable {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-collapse: collapse;
}
.filtersTable input {
  cursor: pointer;
}
.filtersTable thead th {
  font-weight: 600;
  border-bottom: 2px solid #ddd;
  padding-left: 10px;
  padding-right: 10px;
}
.filtersTable thead th #selectAll {
  visibility: hidden;
}
.filtersTable td {
  padding-left: 10px;
  padding-right: 10px;
}
.filtersTable td .actionLinks {
  color: rgb(0, 119, 255);
  cursor: pointer;
}
.filtersTable td input[type=text] {
  width: 6vw;
  border: 0px;
  border-bottom: 1px solid black;
  line-height: normal;
  font-size: 0.9rem;
}
.filtersTable td input[type=text]:focus {
  outline: none;
  border-bottom: 1px solid black;
  font-size: 0.9rem;
}
.filtersTable td select {
  border: 0px;
  margin-top: 5px;
  font-size: medium;
  margin-left: -3px;
}
.filtersTable tr {
  border-top: 1px solid #ddd;
}
.filtersTable .saveLink {
  color: red;
  cursor: pointer;
}
.filtersTable #selectNew {
  visibility: hidden;
}

.addNewFilterButton {
  font-weight: 400;
  color: white;
  border-radius: 2px;
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
  font-size: 0.9em;
  background-color: rgb(33, 139, 33);
  display: inline-block;
  cursor: pointer;
  align-items: center;
}

.removeSelectedFiltersButton {
  margin-left: 10px;
  font-weight: 400;
  color: white;
  border-radius: 2px;
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
  font-size: 0.9em;
  margin-bottom: 10px;
  background-color: rgb(235, 55, 55);
  display: inline-block;
  cursor: pointer;
}

.customFilterWrapper {
  margin-left: 30px;
  display: inline-block;
  vertical-align: top;
}/*# sourceMappingURL=Filters.css.map */