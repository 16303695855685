.FilterWrapper {
  background-color: #fff;
  overflow: hidden;
  width: 20vw;
  height: 100%;
  display: block;
  float: left;
  margin-left: 30px;
}
.FilterWrapper .FilterHeader {
  color: #283747;
  background-color: #f9f9fc;
  border: 0px solid #f5f5f5;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-size: 1.07rem;
  font-weight: 600;
  padding: 1rem 1.33rem;
  border-bottom: 2px solid #ccc;
}
.FilterWrapper .FilterParamsWrapper {
  margin: 0;
  font-size: 1rem;
  color: #004d60;
  background-color: rgba(245, 245, 245, 0.2039215686);
  border-left: 2px solid rgba(192, 192, 192, 0.1568627451);
  border-bottom: 2px solid rgba(192, 192, 192, 0.1568627451);
  border-right: 2px solid rgba(192, 192, 192, 0.1568627451);
}
.FilterWrapper .FilterParamsWrapper .Chevron {
  display: inline;
  float: right;
  /* padding-right: 10px; */
  /* padding-left: 10px; */
  position: relative;
  right: 10px;
  top: 3px;
}
.FilterWrapper .FilterParamsWrapper .Chevron div {
  margin-bottom: 0px;
}
.FilterWrapper .FilterParamsWrapper .FilterParamsHeader {
  height: 2.5rem;
  background-color: rgba(192, 192, 192, 0.1568627451);
  text-align: center;
  margin-bottom: 0px;
}
.FilterWrapper .FilterParamsWrapper .FilterParamsHeader div {
  margin-bottom: 0px;
}
.FilterWrapper .FilterParamsWrapper label {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.FilterWrapper .FilterParamsWrapper input[type=checkbox] {
  cursor: pointer;
}
.FilterWrapper .FilterParamsWrapper .FilterCategory {
  font-size: 1.1rem;
  font-weight: 600;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin-top: 10px;
}
.FilterWrapper .FilterParamsWrapper .FilterCategory p {
  margin-top: 0px;
}

.react-date-picker {
  width: 100%;
  margin-bottom: 0px;
}
.react-date-picker div {
  margin-bottom: 0px;
}

.react-calendar {
  width: inherit;
}

.FilterChoices {
  margin-bottom: 0px;
}
.FilterChoices div {
  margin-bottom: 10px;
}
.FilterChoices .react-date-picker__calendar {
  width: 19.8vw;
  bottom: 180% !important;
  left: -0.1vw !important;
}
.FilterChoices div.react-date-picker__wrapper {
  margin-bottom: 0px;
}
.FilterChoices div.react-date-picker__inputGroup {
  margin-bottom: 0px;
}

.FilterChoices {
  display: none;
}

.FilterChoices.active {
  display: block;
}/*# sourceMappingURL=FilterBlock.css.map */