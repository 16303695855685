// div {
//     margin-bottom: 10px;
//   }

h4 {
  margin-top: 0px;
}

.episodeBlock {
  padding-bottom: 5px;
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 5px;
  border-radius: 10px;
  background-color: #f5f5f534;
}

.dataButton {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 7px;
  border: 1px solid #ed5b4865;

  font-weight: 600;
  font-size: 0.75em;
  color: #ed5a48;
  margin-top: 10px;
}

.dataButton:hover {
  border: 1px solid #b44537;
  cursor: pointer;
  color: #b44537;
}

.ResultsHeader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.ResultsHeader div {
  margin-bottom: 0px;
}

.ResultsHeaderElement1 {
  -webkit-box-flex: 8;
  -ms-flex-positive: 8;
  flex-grow: 8;
}

.ResultsHeaderElement2 {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  cursor: pointer;
}

.episodeParent {
  display: table;
}

.episodeStats span {
  padding-right: 30px;
}
/*# sourceMappingURL=QueryResults.css.map */

.ResultsWrapper {
  background-color: #fff;
  overflow: hidden;
  width: 72vw;
  margin-left: 1vw;
  display: inline-block;
  float: left;
  min-height: 70vh;
  .ResultsHeader {
    color: #283747;
    font-size: 1.07rem;
    font-weight: 600;
    background-color: #f9f9fc;
    border: 0px solid #f5f5f5;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 1rem 1.33rem;
    border-bottom: 2px solid #ccc;
  }
}
