.customCSSwrapper {
  margin-left: 10px;
  min-width: 70%;
  display: inline-block;
  vertical-align: top;
}

.customCSS {
  min-width: 65vw;
  height: 40vh;
}

.customCSSsave {
  display: table;
  padding: 5px;
  margin-top: 10px;
  background-color: #ed5b48;
  border-width: 2px;
  border-color: #b44537;
  border-style: solid;
  border-radius: 5px;
  color: #ffffff;
}/*# sourceMappingURL=CustomCSS.css.map */