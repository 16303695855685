.UserPanel {
  margin-left: 40px;
}
.UserPanel .DataDashboard {
  background-color: rgba(240, 240, 240, 0.8274509804);
}

.DataDashboard {
  margin-right: 40px;
  padding: 10px;
}
.DataDashboard p {
  margin-top: 5px;
}
.DataDashboard span {
  font-weight: 700;
  margin-bottom: 10px;
}
.DataDashboard div {
  margin-bottom: 0px;
}
.DataDashboard table {
  margin-top: 20px;
}
.DataDashboard .DataSetTable {
  width: 97%;
  margin-left: 10px;
  border-collapse: collapse;
  text-align: left;
}
.DataDashboard .DataSetTable .DataSetHeader th {
  border-bottom: 2px solid grey;
  padding: 0px;
  text-align: left;
  padding-bottom: 5px;
}
.DataDashboard .DataSetTable .DataSetRow {
  font-size: 1em;
  font-weight: 400;
}
.DataDashboard .DataSetTable .Checkbox {
  width: 5%;
}
.DataDashboard .DataSetTable .Column1 {
  width: 35%;
  margin-left: 5%;
}
.DataDashboard .DataSetTable .Column1 span {
  cursor: pointer;
  font-weight: 400;
}
.DataDashboard .DataSetTable .Column2 {
  width: 40%;
}
.DataDashboard .DataSetTable .Column3 {
  width: 20%;
}
.DataDashboard .DataSetTable .Column3 .ActionLinks {
  font-weight: 400;
  display: inline-block;
  padding: 2px;
}
.DataDashboard .DataSetTable .Column3 .ActionLinks:hover {
  cursor: pointer;
  border-bottom: 0.5px solid black;
}

.ToogleWrapper {
  display: inline-block;
  margin-bottom: 0px;
}
.ToogleWrapper .Toogle {
  cursor: pointer;
  margin-bottom: 0px;
  padding-bottom: 10px;
  padding-top: 10px;
  text-align: center;
  margin-bottom: 1px solid grey;
}
.ToogleWrapper .Toogle span {
  padding-bottom: 5px;
}
.ToogleWrapper a {
  text-decoration: none;
  color: red;
  font-weight: 600;
}
.ToogleWrapper .Active {
  background-color: rgba(240, 240, 240, 0.8274509804);
}
.ToogleWrapper .Toogle:hover span {
  font-weight: 600;
}

.userDataButton {
  display: block;
  margin-bottom: 10px;
}/*# sourceMappingURL=account.css.map */