.landingMainBody {
  background-color: #f9f9fc;
  height: 1000px;
}
.landingMainBody .landingTitle #welcomeMessage {
  font-size: 3rem;
}
.landingMainBody .landingTitle #pageTitle {
  font-size: 2rem;
  font-weight: 0;
  display: block;
}
.landingMainBody #tryButton {
  text-decoration: none;
}
.landingMainBody .tryButton {
  margin-top: 40px;
  max-width: 300px;
  min-height: 3.3em;
  background-color: #114828;
  border: 2px solid #114828;
  border-radius: 2px;
  box-shadow: 0 10px 10px -7px #000;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}
.landingMainBody .tryButton #tryButtonMessage {
  font-size: 1.5em;
  color: white;
  font-weight: 500;
}
.landingMainBody h2 {
  color: #363942;
  letter-spacing: -0.05em;
  display: block;
}
.landingMainBody .informationBlockWrapper {
  background-color: white;
  padding-top: 30px;
  text-align: center;
  color: rgba(0, 0, 0, 0.671);
  margin-bottom: 0px;
}
.landingMainBody .informationBlockWrapper .informationBlock {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: white;
  padding-top: 10px;
  padding-left: 60px;
  padding-right: 60px;
  padding-bottom: 100px;
}
.landingMainBody .informationBlockWrapper .informationBlock .informationBlockElement {
  text-decoration: none !important;
  padding: 20px;
  cursor: pointer;
  background: #f9f9fc;
  width: 50%;
  min-height: 200px;
  position: relative;
  scroll-padding-block-start: 40px;
  flex: 1 0 15%;
  margin: 10px;
  border-radius: 5px;
}
.landingMainBody .informationBlockWrapper .informationBlock .informationBlockElement h3 {
  color: rgba(0, 0, 0, 0.671);
}
.landingMainBody .informationBlockWrapper .informationBlock .block1 {
  border-top: 3px solid #acd037;
}
.landingMainBody .informationBlockWrapper .informationBlock .block1:hover {
  background-color: #acd037 !important;
}
.landingMainBody .informationBlockWrapper .informationBlock .block1:hover h3 {
  color: #f9f9fc !important;
}
.landingMainBody .informationBlockWrapper .informationBlock .block1:hover p {
  color: #f9f9fc !important;
}
.landingMainBody .informationBlockWrapper .block2 {
  border-top: 3px solid #37cbd0;
}
.landingMainBody .informationBlockWrapper .block2:hover {
  background-color: #37cbd0 !important;
}
.landingMainBody .informationBlockWrapper .block2:hover h3 {
  color: #f9f9fc !important;
}
.landingMainBody .informationBlockWrapper .block2:hover p {
  color: #f9f9fc !important;
}
.landingMainBody .informationBlockWrapper .block3 {
  border-top: 3px solid #ED5A48;
}
.landingMainBody .informationBlockWrapper .block3:hover {
  background-color: #ed5a48 !important;
}
.landingMainBody .informationBlockWrapper .block3:hover h3 {
  color: #f9f9fc !important;
}
.landingMainBody .informationBlockWrapper .block3:hover p {
  color: #f9f9fc !important;
}
.landingMainBody .informationBlockWrapper .block4 {
  border-top: 3px solid #ecd02c;
}
.landingMainBody .informationBlockWrapper .block4:hover {
  background-color: #ecd02c !important;
}
.landingMainBody .informationBlockWrapper .block4:hover h3 {
  color: #f9f9fc !important;
}
.landingMainBody .informationBlockWrapper .block4:hover p {
  color: #f9f9fc !important;
}
.landingMainBody .footer {
  background-color: #f9f9fc;
  text-align: center;
}
.landingMainBody .footer p {
  display: inline-block;
}

.landingTitle {
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: 22%;
  padding-left: 100px;
  padding-right: 50px;
  padding-top: 120px;
  padding-bottom: 130px;
}/*# sourceMappingURL=mainBody.css.map */