.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  img {
    size: 80%;
  }
}
.formField {
  display: block;
}

.form-alert {
  margin-top: 10px;
  color: red;
  font-weight: 600;
}
.center {
  width: 25vw;
  height: auto;
  border: 3px solid #ededee;
  display: flex;
  justify-content: center;
  flex-direction: column;
  form {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  input {
    display: block;
    margin: 10px;
  }
  .inputField {
    padding: 10px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    width: 80%;
  }
  .button {
    min-height: 2em;
    background-color: #114828a9;
    width: 80%;
    border: 0px;
    color: white;
    font-size: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 8px;
    cursor: pointer;
  }

  .logoBlock {
    .imageWrapper {
      text-align: center;
      margin-bottom: 0px;
    }
    img {
      width: 70%;
    }
    margin-top: 5vh;
  }
}
