.landingMainBody {
  background-color: #f9f9fc;
  // background: src("/images/background.jpg");
  // background-size: cover;
  // background-position-x: -30px;
  height: 1000px;

  .landingTitle {
    #welcomeMessage {
      font-size: 3rem;
    }
    #pageTitle {
      font-size: 2rem;
      font-weight: 0;
      display: block;
    }
  }

  #tryButton {
    text-decoration: none;
  }

  .tryButton {
    margin-top: 40px;
    max-width: 300px;
    min-height: 3.3em;
    background-color: #114828;
    border: 2px solid #114828;
    border-radius: 2px;
    -webkit-box-shadow: 0 10px 10px 0px #000;
    box-shadow: 0 10px 10px -7px #000;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;

    #tryButtonMessage {
      font-size: 1.5em;
      color: white;
      font-weight: 500;
    }
  }

  h2 {
    color: #363942;
    letter-spacing: -0.05em;
    display: block;
  }
  .informationBlockWrapper {
    background-color: white;
    padding-top: 30px;
    text-align: center;
    color: rgba(0, 0, 0, 0.671);
    margin-bottom: 0px;
    .informationBlock {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      background-color: white;
      padding-top: 10px;
      padding-left: 60px;
      padding-right: 60px;
      padding-bottom: 100px;

      .informationBlockElement {
        text-decoration: none !important;
        padding: 20px;
        cursor: pointer;
        background: #f9f9fc;
        width: 50%;
        min-height: 200px;
        position: relative;
        scroll-padding-block-start: 40px;
        flex: 1 0 15%;
        margin: 10px;
        border-radius: 5px;

        h3 {
          color: rgba(0, 0, 0, 0.671);
        }
      }

      .block1 {
        border-top: 3px solid #acd037;
      }
      .block1 {
        &:hover {
          background-color: #acd037 !important;

          h3 {
            color: #f9f9fc !important;
          }
          p {
            color: #f9f9fc !important;
          }
        }
      }
    }
    .block2 {
      border-top: 3px solid #37cbd0;
    }
    .block2 {
      &:hover {
        background-color: #37cbd0 !important;
        h3 {
          color: #f9f9fc !important;
        }
        p {
          color: #f9f9fc !important;
        }
      }
    }
    .block3 {
      border-top: 3px solid#ED5A48;
    }
    .block3 {
      &:hover {
        background-color: #ed5a48 !important;
        h3 {
          color: #f9f9fc !important;
        }
        p {
          color: #f9f9fc !important;
        }
      }
    }
    .block4 {
      border-top: 3px solid #ecd02c;
    }
    .block4 {
      &:hover {
        background-color: #ecd02c !important;
        h3 {
          color: #f9f9fc !important;
        }
        p {
          color: #f9f9fc !important;
        }
      }
    }
  }

  .footer {
    background-color: #f9f9fc;
    text-align: center;
    p {
      display: inline-block;
    }
  }
}

div.informationBlockWrapper {
}

.landingTitle {
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: 22%;
  padding-left: 100px;
  padding-right: 50px;
  padding-top: 120px;
  padding-bottom: 130px;
}
