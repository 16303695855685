.projectViewDiv {
  cursor: pointer;
  width: 100%;
  min-height: 30px;
  position: relative;
  padding-bottom: 20px;
  padding-left: 30px;
  background-color: #f9f9fc;
  border: 1px solid #ededee;
  &:hover {
    background-color: #ebebebaf;
  }
}

.projectViewElements {
  width: 50%;
  max-width: 50%;
  display: inline-block;
  top: 0;
}

.fontAwesomeIcon {
  font-size: 25px;
  color: grey;
}

.projectViewDivInner {
  width:91%;
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
  span {
    margin-left: 10px;
    // margin-bottom: 20px;
  }

  .addIcon {
    position: relative;
    float: right;
   // margin-right: 50px;
    padding-top: 5px;
    height: 1.1em;
    margin-left: 10px;
    
  }

  .addIconDownload {
    position: relative;
    float: right;
  //  margin-right: 50px;
    padding-top: 5px;
    height:1.1em;
   
  }

  // .addDownloadIcon {
  //   position: relative;
  //   float: right;
   
  //   bottom: 0;
  //   position:inline;
  //   padding-top: 8px;
  //   padding-right: 10px;
  // }
}
