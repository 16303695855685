.ViewSettings {
  margin-left: 10px;
  min-width: 70%;
  display: inline-block;
  vertical-align: top;
}
.ViewSettings h3,
.ViewSettings h4 {
  margin-left: 20px;
}
.ViewSettings label {
  font-weight: 500;
}

.settingsFormWrapper {
  margin-left: 20px;
}
.settingsFormWrapper input {
  width: 500px;
}

.settingsFormWrapperInfo {
  margin-top: 20px;
  margin-left: 20px;
}
.settingsFormWrapperInfo input {
  width: 500px;
}
.settingsFormWrapperInfo textarea {
  margin-top: 5px;
  width: 500px;
  height: 3rem;
  resize: none;
  overflow: hidden;
  font: 400 13.3333px Arial;
}

.SaveButton button {
  padding: 5px;
  background-color: #ed5b48;
  margin-left: 20px;
  border-width: 2px;
  border-color: #b44537;
  border-style: solid;
  border-radius: 2px;
}
.SaveButton span {
  margin-left: 20px;
  font-size: 1em;
  color: green;
}

.viewsInput {
  display: block;
}

.viewsInputCheckboxWrapper {
  display: block;
}

.viewsInputCheckbox {
  display: inline;
}

.ViewsFormWrapper {
  margin-left: 20px;
}

.ViewsWrapper {
  display: inline-block;
  margin-right: 20px;
  top: 0;
  vertical-align: top;
}
.ViewsWrapper table input {
  width: 75px;
}

.saveViewsButton {
  margin-left: 20px;
  border: 2px solid red;
  border-radius: 5px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 3px;
  padding-bottom: 4px;
  padding-left: 5px;
  padding-right: 5px;
  cursor: pointer;
}/*# sourceMappingURL=ViewSettings.css.map */